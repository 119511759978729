import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Container from "react-bootstrap/Container";
import esLocale from "@fullcalendar/core/locales/es";
import { NavbarFix } from "../../Components";

import "./styles.css";

const NuevaPagina = () => {
  return (
    <div className="container--calendar">
      <NavbarFix />
      <Container>
        <div>
          <h1 className="title--calendar">Calendario de actividades</h1>
        </div>
        <div className="container--full-calendar">
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            locales={[esLocale]}
            events={[
              {
                title: "Taller Música, Colores y Programación",
                start: "2024-02-03",
                end: "2024-05-19",
              },
              {
                title: "Reunión padres de familia",
                start: "2024-01-17",
                end: "2024-01-17",
              },
            ]}
          />
        </div>
      </Container>
      <footer className="footer">
        <p className="mb-0">MI SUPERPODER ES LA PROGRAMACIÓN - ©2022</p>
        <p className="mb-0">Medellín, Colombia</p>
      </footer>
    </div>
  );
};

export default NuevaPagina;
