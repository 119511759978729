import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import React, { useEffect, useState } from "react";
import "./styles.css";
import calendar from "../../assets/images/calendar.png";
import shop from "../../assets/images/shop.png";
import classes from "../../assets/images/class.png";

import logowebsite from "../../assets/images/logo-website.webp";

const NavbarFix = () => {
  const [scrolling, setScrolling] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Navbar
      fixed="top"
      className={`${scrolling ? "scroll" : ""}`}
      expand={false}
    >
      <Container>
        <Nav.Link href="/">
          <Navbar.Brand>
            <img
              src={logowebsite}
              className="d-inline-block align-top"
              width="180"
              height="auto"
              alt="Logo Web Site"
            />
          </Navbar.Brand>
        </Nav.Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
      </Container>
      <Navbar.Offcanvas
        aria-labelledby="offcanvasNavbarLabel-expand-false"
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id="offcanvasNavbarLabel-expand-">
            Mi menú
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Nav.Link href="https://clases.misuperpodereslaprogramacion.com/">
          <div className="item--menu">
            <img
              className="nav-img"
              src={classes}
              alt=""
              width={"30"}
              height={"30"}
            />
            <p>Mis clases</p>
          </div>
        </Nav.Link>
        <Nav.Link href="calendario">
          <div className="item--menu">
            <img
              className="nav-img"
              src={calendar}
              alt=""
              width={"30"}
              height={"30"}
            />
            <p>Calendario</p>
          </div>
        </Nav.Link>
        <Nav.Link href="#">
          <div className="item--menu">
            <img
              className="nav-img"
              src={shop}
              alt=""
              width={"30"}
              height={"30"}
            />
            <p>
              Tienda <b>(Próximamente)</b>
            </p>
          </div>
        </Nav.Link>
      </Navbar.Offcanvas>
    </Navbar>
  );
};

export default NavbarFix;
