import React from "react";
import "./styles.css";

import goals1 from "../../assets/images/g1.webp";
import goals2 from "../../assets/images/g2.webp";
import goals3 from "../../assets/images/g3.webp";

const Cards = () => {
  return (
    <div className="content-cards--goals">
      <div>
        <img
          className="goals-img"
          src={goals1}
          alt=""
          width={"150"}
          height={"150"}
        />
        <p className="title-card-dark">NUESTRA HUELLA</p>
        <p className="paragraph-card-dark">
          Hemos logrado impactar a más de 200 niños y jóvenes aportando
          conocimientos en programación
        </p>
      </div>

      <div>
        <img
          className="goals-img"
          src={goals2}
          alt=""
          width={"150"}
          height={"150"}
        />
        <p className="title-card-dark">NUESTROS PROFES</p>
        <p className="paragraph-card-dark">
          Están capacitados en el área y nos han permitido aportar valiosos
          conocimientos en programación
        </p>
      </div>

      <div>
        <img
          className="goals-img"
          src={goals3}
          alt=""
          width={"150"}
          height={"150"}
        />
        <p className="title-card-dark">NUESTRO IMPACTO</p>
        <p className="paragraph-card-dark">
          No solo a los niños y jóvenes, si no también sus familias se han visto
          beneficiados de este proyecto
        </p>
      </div>
    </div>
  );
};

export default Cards;
