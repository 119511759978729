import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Button from 'react-bootstrap/Button';
import HeaderImg from "../../assets/images/header_web_2_2.webp";
import './styles.css'

const Slider = () => {
  return (
    <Carousel controls={false} indicators={false}>
      <Carousel.Item>
        <img className="d-block w-100 slider-img" src={HeaderImg} alt="First slide" width={"2880"} height={"auto"}/>
        <Carousel.Caption>
          <h3 className="title">¡En mi superpoder es LA PROGRAMACIÓN!</h3>
          <p className="paragraph">Amamos compartir nuestros conocimientos y transformar para el futuro a través de la enseñanza de la programación y la tecnología.</p>
          <a href="https://clases.misuperpodereslaprogramacion.com/" target="_blank" rel="noreferrer">
            <Button className="button-slider" variant="primary">IR A MIS CLASES</Button>
          </a>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Slider;
