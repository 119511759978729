import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import './styles.css';

import mod1 from '../../assets/images/mod1.webp'
import mod2 from '../../assets/images/mod2.webp'
import mod3 from '../../assets/images/mod3.webp'

const Modules = () => {
  return (
    <Container>
      <Row>
        <Col sm={4}>
          <div className="row-modules">
            <img src={mod1} alt="" className="img-modules" width={"416"} height={"auto"}/>
            <p className="paragraph-modules">En este taller los niños aprenderán sobre:</p>
            <ul className="list-modules">
              <li>Qué es un autómata</li>
              <li>Secuencias y patrones</li>
              <li>Diagramas de flujo</li>
            </ul>
          </div>
        </Col>
        <Col sm={4}>
          <div className="row-modules">
            <img src={mod2} alt="" className="img-modules" width={"416"} height={"auto"}/>
            <p className="paragraph-modules">En este taller los niños aprenderán sobre:</p>
            <ul className="list-modules">
              <li>Pseudocódigo</li>
              <li>Variables</li>
              <li>Condiciones y Ciclos</li>
            </ul>
          </div>
        </Col>
        <Col sm={4}>
          <div className="row-modules">
            <img src={mod3} alt="" className="img-modules" width={"416"} height={"auto"}/>
            <p className="paragraph-modules">En este taller los niños aprenderán sobre:</p>
            <ul className="list-modules">
              <li>Funciones</li>
              <li>Estructuras de datos</li>
              <li>Orientado a objetos (POO)</li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Modules;
