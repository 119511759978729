import React from "react";
import "./styles.css";

import logo1 from "../../assets/images/1.webp";
import logo2 from "../../assets/images/2.webp";
import logo3 from "../../assets/images/3.webp";
import logo4 from "../../assets/images/4.webp";

const Cards = () => {
  return (
    <div className="content-cards">
      <div>
        <img
          className="card-img zoom-image"
          src={logo1}
          alt=""
          width={"150"}
          height={"150"}
        />
        <p className="title-card">CREATIVIDAD</p>
        <p className="paragraph-card">
          Soluciones innovadoras a través de la programación para mejorar el
          entorno
        </p>
      </div>

      <div>
        <img
          className="card-img zoom-image"
          src={logo2}
          alt=""
          width={"150"}
          height={"150"}
        />
        <p className="title-card">LÓGICA COMPUTACIONAL</p>
        <p className="paragraph-card">
          Alternativas prácticas a problemas cotidianos mediante actividades de
          desarrollo lógico
        </p>
      </div>

      <div>
        <img
          className="card-img zoom-image"
          src={logo3}
          alt=""
          width={"150"}
          height={"150"}
        />
        <p className="title-card">GENERACIÓN DE IDEAS</p>
        <p className="paragraph-card">
          Multiples opciones e ideas para resolver problemas, buscando llegar a
          la mejor
        </p>
      </div>

      <div>
        <img
          className="card-img zoom-image"
          src={logo4}
          alt=""
          width={"150"}
          height={"150"}
        />
        <p className="title-card">TRABAJO EN EQUIPO</p>
        <p className="paragraph-card">
          Fomentando el intercambio de ideas para alcanzar una solución en
          conjunto
        </p>
      </div>
    </div>
  );
};

export default Cards;
